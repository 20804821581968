import {Circle as CircleStyle, Fill, Stroke, Style, Text, RegularShape} from 'ol/style';
import FillPattern from 'ol-ext/style/FillPattern';
import {toContext} from 'ol/render';
import Polygon from 'ol/geom/Polygon';
import {Icon} from 'ol/style';
//import Feature from 'ol/Feature';

const stroke = new Stroke({color: 'black', width: 2});
const strokex = new Stroke({color: 'red', width: 5});
const fill = new Fill({color: 'white'});
const fillred = new Fill({color: 'red'});
const fillcyan = new Fill({color: 'cyan'});
const fillbrown = new Fill({color: 'brown'});
const fillgreen = new Fill({color: 'rgb(0,200,0)'});
const fillblue = new Fill({color: 'blue'});
const fillyellow = new Fill({color: 'yellow'});
const fillgrey = new Fill({color: 'rgb(169, 169, 169)'});
const font = 'Normal 13px/11px Arial';
const bfont = 'bold 13px/11px Arial';

const labelStyle = new Style({
  text: new Text({
    font: '13px Calibri,sans-serif',
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 4,
    }),
  }),
});


const txtstyle = new Text({
	font:font,
    text:'AB',
    fill: fill,
    stroke: stroke,
    offsetX: 0,
    offsetY: 0,
    placement: [0, 0],
    maxAngle: 0,
    overflow: 0,
    rotation: 0,
  });



function makeGauge(value){
const symbol = [
  [0, 0],
  [4, 2],
  [6, 0],
  [10, 5],
  [6, 3],
  [4, 5],
  [0, 0],
];

let scale=5;
const scaleFunction = function (coordinate) {
  return [coordinate[0] * scale, coordinate[1] * scale];
};
  	const canvas = document.createElement('canvas');
    const vectorContext = toContext(canvas.getContext('2d'), {
      size: [100, 100],
      pixelRatio: 1,
    });
    vectorContext.setStyle(
      new Style({
        fill: new Fill({color: 'rgba(255, 93, 0, 1)'}),
        stroke: new Stroke({color: 'rgba(255, 204, 0, 0.2)', width: 1}),
      })
    );

if (value>0.95){
    vectorContext.drawGeometry(new Polygon([symbol.map(scaleFunction)]));
}



var context = (canvas.getContext('2d'));
 
let c = canvas.width/2/1;
//context.translate(0,0);
        context.beginPath();
       // context.moveTo(c,c);
        context.fillStyle = 'rgba(255, 204, 0, 0.2)';
        context.arc ( c, c, 25, Math.PI-0.2, 2*Math.PI+0.2);
        context.closePath();
        context.fill();
        context.stroke();

		context.translate(0,0);
        context.beginPath();
        context.moveTo(c,c);
        context.fillStyle = "yellow";
        context.arc ( c, c, 20, Math.PI, 2*Math.PI);
        context.closePath();
        context.fill();
        context.stroke();



 		context.translate(0,0);
        context.beginPath();
        context.moveTo(c,c);
        context.fillStyle = "blue";
        context.arc ( c, c, 20, Math.PI, (Math.PI+value*Math.PI));
        context.closePath();
        context.fill();
        context.stroke();
    
return canvas;
}



function makePanel(value){
const symbol = [
  [0, 0],
  [4, 2],
  [6, 0],
  [10, 5],
  [6, 3],
  [4, 5],
  [0, 0],
];

let scale=5;
const scaleFunction = function (coordinate) {
  return [coordinate[0] * scale, coordinate[1] * scale];
};
  	const canvas = document.createElement('canvas');
    const vectorContext = toContext(canvas.getContext('2d'), {
      size: [100, 100],
      pixelRatio: 1,
    });
    vectorContext.setStyle(
      new Style({
        fill: new Fill({color: 'rgba(255, 93, 0, 1)'}),
        stroke: new Stroke({color: 'rgba(255, 204, 0, 0.2)', width: 1}),
      })
    );

if (value>0.95){
    vectorContext.drawGeometry(new Polygon([symbol.map(scaleFunction)]));
}

var context = (canvas.getContext('2d'));
 
let c = canvas.width/2/1;
//context.translate(0,0);
        context.beginPath();
       // context.moveTo(c,c);
        context.fillStyle = 'rgba(255, 204, 0, 0.2)';
        context.arc ( c, c, 25, Math.PI-0.2, 2*Math.PI+0.2);
        context.closePath();
        context.fill();
        context.stroke();

		context.translate(0,0);
        context.beginPath();
        context.moveTo(c,c);
        context.fillStyle = "yellow";
        context.arc ( c, c, 20, Math.PI, 2*Math.PI);
        context.closePath();
        context.fill();
        context.stroke();

    
return canvas;
}

const geostyle = {


userdwg: new Style({
      image: new Icon({
        img: makeGauge(0.5),
        imgSize: [100, 100],
        rotation: 0,
      }),
    }),





dynamicstyle: function (type,pic,bigdata,loaicongtrinh,value){

//strs.length

let ipanel = [];

ipanel.push(new Style({
      	 image:  new Icon({
          anchor: [0.5, 1],
          src: pic,
        }),
    	}))

let i=0;

for (f in bigdata) {
	i += 15;
	if ((f!='lat') && (f!='lon')) {
	let fn = (f=='name')?loaicongtrinh:f;	
	let itemdata =  fn + ': ' + bigdata[f];
	
	let notice= new Style({
	text: new Text({
	font:font,
    text:itemdata,
    fill: fill,
    stroke: stroke,
    offsetX: 0,
    offsetY: i,
    placement: [0, 0],
    maxAngle: 0,
    overflow: 0,
    rotation: 0,
  }),
	 });
	
	ipanel.push(notice)
	

	}
} 


	

const dstyle = {

gauge: new Style({
      image: new Icon({
        img: makeGauge(value),
        imgSize: [100, 100],
        rotation: 0,
      }),
    }),


panel: ipanel,

};

switch(type) {
	
  case "gauge":
	
	return dstyle.gauge;

  case "panel":
	
	return dstyle.panel;
    
  default:
    return geostyle[type];
}




}






	}




	
module.exports = geostyle
//export default pat;