const hostIP = '203.205.43.94'

const thuyloistyle = require('/thuyloistyle');

var founds=[];

$('#bfind').click(function(){
	
	timtheoloai(',ho,dap,bom,moclu,kesontra,kebvbo,kegiaothong,pttt,nguon,tuoi,qhho,qhdap,qhbom,qhkenh,qhde,qhcong,qhnguon,truccanh,trama,tramb');
	
})


function timtheoloai(loaict){
	
const url = "http://"+hostIP+":8000/selectdb/SELECT row_number() over(order by id) as id,id as idx, name,st_astext(st_transform(st_setsrid(geom, 4326), 3857)) as geom FROM public.congtrinhthuyloi WHERE position((',' || field) in '," + loaict + "')>0";
console.log(url)
finddb(url);

async function finddb(url) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });
founds = await myPromise0;
//console.log(founds);
founddisplay();
}
}


function founddisplay(){
	found_table = new Tabulator("#found-table", {
	layout:"fitDataStretch",
//	headerFilterParams:{values:true},
	height:"300px",
	selectable:1,
    data:founds,           //load row data from array
    //layout:"fitDataStretch",
	//layout:"fitColumns",      
   // history:true,             //allow undo and redo actions on the table
    columns:[                 //define the table columns
        
		{title:"Mã", field:"id",hozAlign:"center",sorter:"number", width:10},
		{title:"co", field:"idx",visible:false},
		{title:"Tên", field:"name",visible:true, width:60,formatter:"textarea",headerFilter:"input"},
		
  ],
	rowClick:function(e, row){ //trigger an alert message when the row is clicked
 //	alert("Row " +founds[row.getData().id-1].geom  + " Clicked!!!!");
let geostr=founds[row.getData().id-1].geom;
let coorstr = (geostr.indexOf('((')>0)?geostr.split('((')[1]:geostr.split('(')[1];
var x = window.innerWidth / 2;
var y = window.innerHeight / 2;
let coor = [2];
	coor[0]=parseFloat(coorstr.split(' ')[0]);
	coor[1]=parseFloat(coorstr.split(' ')[1]);
	
	//const size = [200,100];
	//const viewResolution = map.getView().getResolution();
	//map.getView().setResolution(3.5);
	//console.log('viewResolution',viewResolution)


//	map.getView().animate({
//	  zoom: 25,
//	  duration: 250
//	})

	map.getView().setZoom(17);

	const size = map.getSize();
	console.log('coor',coor,size,x,y);
    view.centerOn(coor, size, [x,y]);

const p = new Point(coor);
	highlight.setGeometry(p);

//featureOverlay.getSource().removeFeature(highlight);


 	},
    rowSelected:function(row){
		
    },
 	
	rowDeselected:function(row){
    },

});

}


function findpanel(){

$('#panel3').show();
$('#panel1').hide();
$('#panel2').hide();

//founddisplay()


}


//const pat = require('global');
//const iotstyle = require('/dist/iotstyle');

//var infopopup = require('/infopopup');
//Profi

//import Profil from 'ol-ext/control/Profile';


//import './js/test.js';

//import 'ol-ext/dist/ol-ext.css';

import FillPattern from 'ol-ext/style/FillPattern'

import Profil from 'ol-ext/control/Profile'
import 'ol-ext/dist/ol-ext.css'

import 'ol/ol.css';
//import Draw from 'ol/interaction/Draw';
//import 'ol-ext/control/Legend.css';
//import Legend from 'ol-ext/ol_legend_Legend';
//import Legend from 'ol-ext/control/Legend';
import Graticule from 'ol/layer/Graticule';
import {Draw, Modify, Snap, Select,defaults as defaultInteractions,} from 'ol/interaction';

import {DragRotateAndZoom,defaults as defaultInteractions,} from 'ol/interaction';

import Map from 'ol/Map';
import Overlay from 'ol/Overlay';

import {Control, defaults as defaultControls} from 'ol/control';
import View from 'ol/View';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import {LineString, Polygon} from 'ol/geom';
import {Cluster,OSM, Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
//import {getArea, getLength} from 'ol/sphere';
//import {unByKey} from 'ol/Observable';
import XYZ from 'ol/source/XYZ';
import PinchZoom from 'ol/interaction/PinchZoom';
import {Heatmap as HeatmapLayer} from 'ol/layer';
//import {Attribution, defaults as defaultControls} from 'ol/control';
//import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from 'geotiff';
import {unByKey} from 'ol/Observable';

//import ImageWMS from 'ol/source/ImageWMS';
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer';
//import Snap from 'ol/interaction';

import GeoJSON from 'ol/format/GeoJSON';

//import MultiPoint from 'ol/geom/MultiPoint';
import Point from 'ol/geom/Point';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature';

import {Icon} from 'ol/style';

import proj4 from 'proj4';

//import proj4 from 'proj4';

import {register} from 'ol/proj/proj4';
//import {transform} from 'ol/proj';
//import {getCenter} from 'ol/extent';
//import Static from 'ol/source/ImageStatic';


import {altKeyOnly, click, pointerMove} from 'ol/events/condition';

import {ScaleLine} from 'ol/control';
import {getPointResolution, get as getProjection} from 'ol/proj';

//style
import { RegularShape} from 'ol/style';


import Bar from 'ol-ext/control/Bar'
import Button from 'ol-ext/control/Button'
//import TextButton from 'ol-ext/control/TextButton'
//import Toggle from 'ol-ext/control/Toggle'

//import Feature from 'ol/Feature';

//import {watercolor} from '../layer/layers'
//import dep from '../data/dep'

//import Feature from 'ol/Feature'
//import Polygon from 'ol/geom/Polygon'
import Crop from 'ol-ext/filter/Crop'

import { jsPDF } from "jspdf";


const info = document.getElementById('info');
var crop;


const stroke = new Stroke({color: 'black', width: 2});
const fill = new Fill({color: 'white'});
const fillred = new Fill({color: 'red'});
const fillgreen = new Fill({color: 'rgb(0,200,0)'});
const fillblue = new Fill({color: 'blue'});
const fillyellow = new Fill({color: 'yellow'});
const font = 'Normal 15px/10px Arial';


// Define rotate to north control.
//
var panel

class InputPicControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = '<i class="fa fa-book"></i>';

    const button0 = document.createElement('button');
    button0.innerHTML = '<i class="fa fa-coffee"></i>' ;

    const button1 = document.createElement('button');
    button1.innerHTML = '<i class="fa fa-search"></i>';

    const button2 = document.createElement('button');
    button2.innerHTML = '<i class="fa fa-print"></i>';

    const button3 = document.createElement('button');
    button3.innerHTML = '<i class="fa fa-coffee"></i>' ;

    const element = document.createElement('div');
 
    //element.className = 'rotate-north ol-unselectable ol-control';

	element.className ='verticalmenu';
	
    element.appendChild(button);
	element.appendChild(button0);
	element.appendChild(button1);
	element.appendChild(button2);
	//element.appendChild(button3);
	
    super({
      element: element,
      target: options.target,
    });
    button.addEventListener('click', this.handlePanel1.bind(this), false);
	button0.addEventListener('click', this.handlePanel2.bind(this), false);
	button1.addEventListener('click', this.handlePanel3.bind(this), false);


    button2.addEventListener('click', this.handlePanel4.bind(this), false);
	//button3.addEventListener('click', this.handleTest.bind(this), false);
  }


handlePanel1() {
panel=true;
	
$('#panel1').toggle();
$('#panel2').hide();
$('#panel3').hide();
$('#panel4').hide();
printmode=false;
//restorelayer();

}

handlePanel2() {
panel=true;	
$('#panel2').toggle();
$('#panel1').hide();
$('#panel3').hide();
$('#panel4').hide();
printmode=false;
}


handlePanel3() {
panel=true;




if (layermenu.length==0){
	$('#findheader').html('<strong>Tất cả</strong>')
	timtheoloai(',ho,dap,bom,moclu,kesontra,kebvbo,kegiaothong,pttt,nguon,tuoi,qhho,qhdap,qhbom,qhkenh,qhde,qhcong,qhnguon,truccanh,trama,tramb');
} else {
			//	if (founds.length==0){
					$('#findheader').html('<strong>Đang hiển thị</strong>')
			let loaistr='';
			for (let i=0;i<layermenu.length-3;i++){
			
				if (layerselected.indexOf(''+layermenu[i].getData().id)>-1) {
					loaistr += ','+layermenu[i].getData().loai;
				} 
				
			}
				console.log('hien co',loaistr);
				timtheoloai(loaistr);
			//}
	
}



$('#panel3').toggle();
$('#panel1').hide();
$('#panel2').hide();
$('#panel4').hide();
printmode=false;
}

handlePanel4() {


if (printmode) {
	$('#panel4').hide();
	printmode=false;
} else {
	$('#panel4').show();
	printmode=true;
	panel=true;
}

$('#panel1').hide();
$('#panel2').hide();
$('#panel3').hide();
//restorelayer();

}

handlePrint(){
	console.log('click print');
	$( ".ol-print").show();
	$( ".ol-print").click();
	//$( ".ol-print").hide();
	
}


handleTest() { 
InputMode=true;
//alert('me')	;


//$('#myModal').modal('show');
//let mymodal = document.getElementById('myModal')
//mymodal.style.display='block'
//mymodal.style.top="100px"

let cen = this.getMap().getView().getCenter()
	popupicon.setPosition(cen);
	
}


handleInputPic() {
	
    //this.getMap().getView().setRotation(0);
//InputMode=true;
//let cen = this.getMap().getView().getCenter()
//	content.innerHTML = `<div>
//  <iframe style="width:300px;height:150px" src="http://` +  hostIP + `:8000/up?abc"></iframe>
//</div>`
//	pedit.style.display="none";
//    popup.setPosition(cen);

$('#panel').show();

  }
}




const txtstyle = new Text({
	font:font,
    text:'AB',
    fill: fill,
    stroke: stroke,
    offsetX: 0,
    offsetY: 0,
    placement: [0, 0],
    maxAngle: 0,
    overflow: 0,
    rotation: 0,
  });




const iotstyle = {

boundary: new Style({
        stroke: new Stroke({
          width: 3,
          color: 'red',
        }),
      }),

boundary1: new Style({
        stroke: new Stroke({
          width: 3,
		   lineDash: [7, 7],
          color: 'blue',
        }),
      }),

boundary2: new Style({
        stroke: new Stroke({
          width: 3,
lineDash: [7, 7],
          color: 'red',
        }),
      }),

boundary3: new Style({
        stroke: new Stroke({
          width: 3,
lineDash: [7, 7],
          color: 'brown',
        }),
      }),

boundary4: new Style({
        stroke: new Stroke({
          width: 2,
//lineDash: [7, 7],
          color: 'black',
        }),
      }),

flood: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/flood.png',
        }),
      }),

pump: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/pump.png',
        }),
      }),

dam: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/dam.png',
        }),
      }),

pool: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/pool.png',
        }),
      }),

icon0: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/icon.png',
        }),
      }),
      
icon1: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/p1.png',
        }),
      }),
      
icon2: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/p2.png',
        }),
      }),
icon3: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/flood.png',
        }),
      }),
icon4: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/p2.png',
        }),
      }),

      'geoMarker': new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({color: 'red'}),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
        }),
      }),
    
dynamicstyle: function (type,value,strvalue,small){

var strvalueout = (small ? strvalue : '');

//console.log('run here...',small, strvalue, strvalueout)


const dstyle = {


pointstyle:	[
	new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data/p2.png',
        }),
      }),
	
	new Style({
    text: new Text({
	font:font,
    text:strvalueout,
    fill: fill,
    stroke: stroke,
    offsetX: 0,
    offsetY: 0,
    placement: [0, 0],
    maxAngle: 0,
    overflow: 0,
    rotation: 0,
  	}),
	}),

],

polystyle: new Style({
//        stroke: new Stroke({
//          width: 1,
//          color: [0, 0, 222, 0.3],
//        }),
	    fill: new Fill({
	      color: 'rgba(0, 0, 128,' + value + ')',
	    }),
      }),


}


switch(type) {
  case "pointstyle":
	
    return dstyle.pointstyle;

  case "polystyle":
    return dstyle.polystyle;

  default:
    return iotstyle[type];
}



},

}
//end style

//test ve


const urlpoint = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.pjson;"


const urliconthuyloi = "http://"+hostIP+":8000/selectdb/SELECT * FROM public.icon;"

const urlhothuyloi = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_ho;"
const urlbomthuyloi = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_bom;"
const urlmocluthuyloi = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_moclu;"
const urldapdangthuyloi = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_dapdang;"
const urltramtruccanhthuyloi = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_tramtruccanh;"



const urlkethuyloi1 = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_kesontra;"
const urlkethuyloi2 = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_kebvbo;"
const urlkethuyloi3 = "http://"+hostIP+":8000/selectdb/SELECT geojson FROM public.v_kegiaothong;"

var layermenu = [];
var layerselected =localStorage.getItem("layerselected");
console.log('start',layerselected)
if (layerselected===null) {
	layerselected ="1103,1105,1107,1203,1205,1102,1101,2101"
}

var floodlayers = [];
let geolocal; 
var icons=[];
var drop1 = ` <select class="pvalue1" id="iconselect"  style="width:70%">
			    @option
			  </select>`



function groupIcon(loai){
let gricon = icons.find(el => el.field == loai);
//console.log(loai,gricon);
if (gricon) {
	return gricon["groupicon"];
} else {
	return 'icon.png';
}
}

function itemIcon(loai){
let gricon = icons.find(el => el.field == loai);
if (gricon) {
	return gricon["itemicon"];
} else {
	return 'icon.png';
}
}



function imageExists(i){
let urlimg = "http://" + hostIP + ":8000/checkfileexist?&file=C:/project/bandothuyloi/dist/icon/"+ icons[i].itemicon;

checkimg(urlimg);

async function checkimg(url) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

let re = await myPromise0;
console.log('check file exixt',icons[i].itemicon,re.exists);
if (!re.exists) {
	icons[i].itemicon='icon.png';
}
}
};

function imageExists1(i){
let urlimg = "http://" + hostIP + ":8000/checkfileexist?&file=C:/project/bandothuyloi/dist/icon/"+ icons[i].groupicon;

checkimg(urlimg);

async function checkimg(url) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

let re = await myPromise0;
console.log('check file exixt',icons[i].groupicon,re.exists);
if (!re.exists) {
	icons[i].groupicon='icon.png';
}
}
};







GetIcon(urliconthuyloi)

async function GetIcon(url) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

 icons = await myPromise0;
let xicon = ''
for (let i=0;i<icons.length;i++){
	
//imageExists(i);
//imageExists1(i);
	
	if(icons[i].field==='chung'){
		xicon += '<option value="' + icons[i].itemicon + '">' + icons[i].itemicon + '</option>'
	}
}

drop1 = drop1.replace('@option',xicon);
console.log(icons);

displayicon(1);
//{'id':1301,'kenh':'Trạm trực canh','loai':'truccanh','dientich':11},
//{'id':1101,'kenh':'Công trình hồ chứa','loai':'ho','dientich':11},
//{'id':1102,'kenh':'Công trình đập','loai':'dap','dientich':11},
//{'id':1103,'kenh':'Công trình trạm bơm','loai':'bom','dientich':11},
//{'id':1104,'kenh':'Cột mốc bão lũ','loai':'moclu','dientich':11},
//{'id':1105,'kenh':'Kè bán đảo Sơn Trà','loai':'kesontra','dientich':11},
//{'id':1106,'kenh':'Kè bảo vệ bờ','loai':'kebvbo','dientich':11},
//{'id':1107,'kenh':'Kè giao thông','loai':'kegiaothong','dientich':11},
//{'id':1108,'kenh':'Nhà phòng tránh thiên tai','loai':'pttt','dientich':11},
//{'id':1109,'kenh':'Công trình tạo nguồn','loai':'nguon','dientich':11},
//{'id':1110,'kenh':'Khu tưới','loai':'tuoi','dientich':11}]}


velopthuyloi('Công trình hồ chứa','ho',0,false);
velopthuyloi('Công trình đập','dap',0,false);
velopthuyloi('Công trình trạm bơm','bom',0,false);
velopthuyloi('Cột mốc bão lũ','moclu',0,false);
velopthuyloi('Trạm trực canh','truccanh',0,false);
velopthuyloi('Nhà phòng tránh thiên tai','pttt',0,false);
velopthuyloi('Công trình tạo nguồn','nguon',0,false);

velopthuyloi('Đường bao','biengioi',4,false);
velopthuyloi('Khu tưới','tuoi',0,false);

velopthuyloi('Kè bán đảo Sơn Trà','kesontra',1,false);
velopthuyloi('Kè bảo vệ bờ','kebvbo',2,false);
velopthuyloi('Kè giao thông','kegiaothong',3,false);

//GetHoThuyLoi(urlhothuyloi,false);
//GetBomThuyLoi(urlbomthuyloi,false);
//GetMocLuThuyLoi(urlmocluthuyloi,false);
//GetDapDangThuyLoi(urldapdangthuyloi,false);
//GetTramTrucCanhThuyLoi(urltramtruccanhthuyloi,false);
//GetKeThuyLoi1(urlkethuyloi1,false);
//GetKeThuyLoi2(urlkethuyloi2,false);
//GetKeThuyLoi3(urlkethuyloi3,false);

}


async function velopthuyloi(tenct,loaict,loaikyhieu,vis) {
	let url="http://"+hostIP+":8000/selectdb/" + `SELECT row_to_json(fc.*) AS geojson
   FROM ( SELECT 'FeatureCollection'::text AS type,
            array_to_json(array_agg(f.*)) AS features
           FROM ( SELECT 'Feature'::text AS type,
                    st_asgeojson(st_transform(st_setsrid(lg.geom, 4326), 3857), 15, 0)::json AS geometry,
                    row_to_json(ROW(lg.id, lg.name, lg.descriptio, lg.field, lg.bigdata)) AS properties
                   FROM congtrinhthuyloifix lg
                  WHERE lg.field = '` + loaict + `'::text
                  ORDER BY lg.id) f) fc;`
	
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
let stylex="pump"
if (loaikyhieu>0){
	stylex=""+loaikyhieu;
}
addmultifield(geo,tenct,loaict,vis,stylex);
}





async function GetHoThuyLoi(url,vis) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
console.log('ho nuoc',geo);
addmultifield(geo,'Công trình hồ chứa',vis,"pool");

}



async function GetBomThuyLoi(url,vis) {
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield(geo,'Công trình trạm bơm',vis,"pump");
}


async function GetMocLuThuyLoi(url,vis) {
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield(geo,'Cột mốc bão lũ',vis,"flood");
}



async function GetDapDangThuyLoi(url,vis) {
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield(geo,'Công trình đập',vis,"dam");
}



async function GetTramTrucCanhThuyLoi(url,vis) {
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield( geo,'Trạm trực canh',vis,"pump");
}



async function GetKeThuyLoi1(url,vis) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield(geo,'Kè bán đảo Sơn Trà',vis,"1");
};



async function GetKeThuyLoi2(url,vis) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield(geo,'Kè bảo vệ bờ',vis,"2"); 
}


async function GetKeThuyLoi3(url,vis) {
//console.log('waiting',url);
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cflood = await myPromise0;
const geo = cflood[0].geojson;
addmultifield(geo,'Kè giao thông',vis,"3");
}


const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

const psave = document.getElementById('psave');
const pedit = document.getElementById('pedit');
const pdel = document.getElementById('pdel');
const upimg = document.getElementById('uploadimages');
const urlimg = document.getElementById('urlimage');
const imgfilename = document.getElementById('imgfilename');



//const eraseObj = document.getElementById('popup-run');
//const saveObj = document.getElementById('popup-save');
//const hideObj = document.getElementById('popup-hide');
//const saveCoor = document.getElementById('popup-coor');
const element = document.getElementById('popup');
const elementfile = document.getElementById('popupicon');

//elementimg.style.display='block';

const popup = new Overlay({
  element: element,
  width:400,	
  positioning: 'bottom-center',
  stopEvent: true,
  offset: [0, -10],
});

const popupicon = new Overlay({
  element: elementfile,
  width:400,	
  positioning: 'bottom-center',
  stopEvent: true,
  offset: [0, -10],
});



closer.onclick = function () {
  popup.setPosition(undefined);
  closer.blur();
  InputMode=false;	
  return false;
};




//15.9537873

//proj4.defs(
//  'EPSG:27700',
//  '+proj=tmerc +lat_0=15.9558873 +lon_0=108.1551602 +k=250.0 ' +
//    '+x_0=-20000 +y_0=400000 +ellps=airy ' +
//    '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
//    '+units=m +no_defs'
//);
//
//register(proj4);


proj4.defs(
'DH1000',
'+proj=tmerc +lat_0=0 +lon_0=108 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'
);



//PROJCS["dh10000",GEOGCS["dh10000",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]],VERTCS["VN2000",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PARAMETER["Vertical_Shift",0.0],PARAMETER["Direction",1.0],UNIT["Meter",1.0]]


register(proj4);


//proj4.defs(
//  'EPSG:27700',
//  '+proj=tmerc +lat_0=15.9558873 +lon_0=108.1551602 +k=250.0 ' +
//    '+x_0=-20000 +y_0=400000 +ellps=airy ' +
//    '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
//    '+units=m +no_defs'
//);
//
//register(proj4);



var drawMode = 0;


//const imageExtent = [462147.404650,1778664.259870,572247.404650,1696604.259870];

//const imageExtent = [0, 0, 700000, 1300000];

//const imageLayer = new ImageLayer({
//	
//	title: 'kb1',
//	visible:false,
//    source: new Static({
//    url:'images/kb1.png',
//    crossOrigin: '',
////    projection: 'EPSG:27700',
//    projection: 'DH1000',
//    imageExtent: imageExtent,
//    imageSmoothing: true,
//	
//  })
//	
//});

//const base =
//  'https://landsat-pds.s3.amazonaws.com/c1/L8/139/045/LC08_L1TP_139045_20170304_20170316_01_T1/LC08_L1TP_139045_20170304_20170316_01_T1';
//
//const min = 10000;
//const max = 15000;
//
//const tiffsource = new GeoTIFF({
//  sources: [
//    {
//      //url: `images/KQ_H2009_DHHT_Clip.tif`,
//      //overviews: [`${base}_B6.TIF.ovr`],
//      url: `${base}_B6.TIF`,
//      overviews: [`${base}_B6.TIF.ovr`],
//      
//	  min: min,
//      max: max,
//      nodata: 0,
//    },
//]
//});


// const tifflayer =  new TileLayer({
//      style: {
//        saturation: -0.3,
//      },
//      source: tiffsource,
//    });


 const glayer =  new TileLayer({
	  title: 'Google Sattelite Map',
	  visible:false,
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
      })
    });


 const glayer1 =  new TileLayer({
	  title: 'Google Street Map',
	  visible:false,
      source: new XYZ({
        //url: 'http://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
			url: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
      })
    });


var clgeos =[]
// tao nhieu object cung layer iot
var geoiots =[]

function addiotlayer(geo){
// dung truong f2 lam style

geoiots =[]

if(geo.features){
	
var fn = geo.features.length;

for (let i=0;i<fn;i++) {

var feaX = new GeoJSON().readFeatures(geo.features[i]);

feaX[0].set('mag',i+1);
geoiots.push(feaX[0]);

};

//console.log('geo heAT',geoiots);

const Hvector = new HeatmapLayer({
  title: "HEATMAP",
  source: new VectorSource({features: geoiots,}),
  
  blur: 10,
  radius: 10,
  weight: function (feature) {
    const mag = feature.get('mag');
    return mag/5;
  },
});


//map.addLayer (Hvector);

};
};


async function GetHpoint(url) {

  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

const cpoly = await myPromise0;
const geo = cpoly[0].geojson;
//console.log('Hpoint',geo);


addiotlayer(geo);
};

GetHpoint(urlpoint);


var testpoint = new Point(fromLonLat([108.1551602,15.9537873]));

//console.log(testpoint);

const iconFeature = new Feature({
	tyle: 'icon1',
  	geometry: new Point(fromLonLat([108.159, 16.05])),

});





const position = testpoint;

const iconStyle =[ new Style({
						  image: new Icon({
						    anchor: [0.5, 46],
						    anchorXUnits: 'fraction',
						    anchorYUnits: 'pixels',
						    src: 'data/icon.png',
						  }),
						}),
					new Style({
						  image: new Icon({
						    anchor: [0.5, 46],
						    anchorXUnits: 'fraction',
						    anchorYUnits: 'pixels',
						    src: 'data/p1.png',
						  }),
						}),
					new Style({
						  image: new Icon({
						    anchor: [0.5, 46],
						    anchorXUnits: 'fraction',
						    anchorYUnits: 'pixels',
						    src: 'data/p2.png',
						  }),
						}),		
						]

//iconFeature.setStyle(iconStyle);

const Psource = new VectorSource({
        features: [iconFeature ],
      	style: function (feature) {
        return iconStyle[1];
      },
    });

//const lp =	new VectorLayer({ title: "test point",
//							  visible:true,	
//							  source: Psource
//									  });
//map.addLayer (l);






const highlightStyle = new Style({
  stroke: new Stroke({
    color: 'red',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255,255,0,0.3)',
	//	color: 'yellow',
  }),

 
  image: new CircleStyle({
          radius: 7,
          fill: new Fill({color: 'orange'}),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
   }),
 
//  text: new Text({
//    font: '12px Calibri,sans-serif',
//    fill: new Fill({
//      color: '#000',
//    }),
//    stroke: new Stroke({
//      color: '#f00',
//      width: 3,
//    }),
//  }),
});

//var featurecollect = [];
//
//featurecollect.push(iconFeature);
////featurecollect.push(new Feature(testpoint));
//featurecollect.push(geoMarker);
//


function modifyfieldbylayername (layername,loai) {
	console.log('modify layer',layername,loai);
	
		map.getLayers().forEach(function(l) {
			if (l!==undefined){
				let ln=l.get("title");
				if(ln===layername){
					map.removeLayer(l);
					velopthuyloi( layername,loai,true);
				}
			}
      });
	
}


function modifyfield(layerp,loai){
console.log('modify',layerp.get('title'),loai);
let lname =layerp.get('title');
			map.removeLayer(layerp);
			velopthuyloi( lname,loai,true);
      };

	
// tao nhieu object cung layer
function addmultifield(geo,lname,loaict,visible,substyle){
// dung truong f2 lam style

var geos =[]

var layertitle = ''

var iconstyle = false;

ctfeatures=[];

if(geo.features){
	
var fn = geo.features.length;

for (let i=0;i<fn;i++) {

var feaX = new GeoJSON().readFeatures(geo.features[i]);
var typeheader='';

var styleid =String(parseInt(feaX[0].get('f2')) % 11);

var stylevalue =parseInt(feaX[0].get('f2'));

//console.log(' getType: ',feaX[0].getGeometry().getType());

if (feaX[0].getGeometry().getType()==='MultiLineString'){
	typeheader='boundary'+substyle;
	layertitle = lname;
	}

if (feaX[0].getGeometry().getType()==='Polygon'){
	typeheader='boundary'+substyle;
	layertitle = lname
	}

if (feaX[0].getGeometry().getType()==='Point'){
	typeheader=substyle;
	stylevalue = 0.1;
	layertitle = lname;
//	feaX[0].set('groupicon',groupIcon(feaX[0].get('f4')));
//	feaX[0].set('itemicon',itemIcon(feaX[0].get('f4')));
	ctfeatures.push(feaX[0]);	
	iconstyle = true;								
};

if (feaX[0].getGeometry().getType()==='MultiPolygon'){
	typeheader='boundary'+substyle;
	stylevalue = 0;
	layertitle = lname;
//	console.log('here',typeheader+styleid)
//	
//
//	let obj = feaX[0].getGeometry();
//	let ge = obj.transform('EPSG:4326', 'EPSG:3857');
//	let ge = obj.transform('EPSG:4756', 'EPSG:3857');
//
//	const crds =ge.getCoordinates();
//	//feaX[0].setGeometry(crds); 
//	
//	feaX[0].getGeometry().setCoordinates(crds);
	
	
	}



	feaX[0].set('type1',typeheader);
	//console.log(feaX[0].get('type1'),stylevalue,feaX[0].get('f1'));
	//clusterSource.getSource().clear();


	geos.push(feaX[0]);


};

//console.log('geos->',geos);

//clusterSource.getSource().addFeatures(geos);

console.log('lnames->',lname);

	if (iconstyle){
		
	makecluster(ctfeatures,lname,loaict,visible);
	} 
		
	else {
		console.log('kieu duong->',lname);
		const l =	new VectorLayer({ title: layertitle,
							  visible:visible,	
							  source: 	new VectorSource({
									 	features: geos,
											}),
										style: function (feature) {
											
											//console.log('fea type =',feature.get('type1'));
										//	return iotstyle.boundary;
										return iotstyle[feature.get('type1')];
										//return pat.stylesGeo[feature.get('type')];
																	},
																	
								//projection: 'SR-ORG:8978',crossOrigin: '',
																	
									  });


	map.addLayer (l);
	//floodlayers.push(l);
	//user_table.selectRow("visible"); 
		
	}

};

restorelayer();
};


//



ctfeatures = [];
//const e = 12041992; //4500000;
//for (let i = 0; i < count; ++i) {
//  const coordinates = [2 * e * Math.random() - e, 2 * e * Math.random() - e];
//  features[i] = new Feature(new Point(coordinates));
//}

var printmode;

function makecluster(feas,lname,loai,vis){
const source1 = new VectorSource({
  features: feas,
});

const clusterSource = new Cluster({
  distance: 80,
  minDistance: 60,
  source: source1,
});

const styleCache1 = {};
const styleCache2 = {};
const pic1 = 'icon/' + groupIcon(loai);
const pic2 = 'icon/' + itemIcon(loai);


console.log('pic',lname,pic1,pic2);

const clusters = new VectorLayer({
  title: lname,
  source: clusterSource,
  visible:vis,
  style: function (feature, resolution) {
    const size = feature.get('features').length;
	var str2 = feature.get('features')[0].get('f2');
	var bigdata = feature.get('features')[0].get('f5');
	//console.log('cluster fea',feature.get('features')[0].get('f2'));
	
    let style1 = styleCache1[size];
	let style2 = styleCache2[size];
	//console.log('res',resolution);
	
    if (!style1) {
	
      style1 = new Style({
//        image: new CircleStyle({
//          radius: 10,
//          stroke: new Stroke({
//            color: '#fff',
//          }),
//          fill: new Fill({
//            color: '#3399CC',
//          }),
//        }),


//		image: new Icon({
//          anchor: [0.5, 1],
//          src: pic1,
//        }),

        image:  new Icon({
          anchor: [0.5, 1],
          src: pic1,
        }),

//		image:	new CircleStyle({
//          radius: 10,
//          stroke: new Stroke({
//            color: '#fff',
//          }),
//          fill: new Fill({
//            color: '#3399CC',
//          }),
 //       }),


        text: new Text({
		  font:font,	
          text: size.toString(),
          fill: new Fill({
            color: 'black',
          }),
        }),
      });
   //   styleCache[size] = style;
    };

if (!printmode) {
      style2 = new Style({
		image: new Icon({
          anchor: [0.5, 1],
          src: pic2,
        }),

        text: new Text({
          text: str2,
          fill: new Fill({
            color: 'black',
          }),
        }),
      });
   
    } else {
	
	
	 style2 =thuyloistyle.dynamicstyle("panel",pic2,bigdata,str2,100);
	
}

     


if ( size==1) {
	styleCache2[size] = style2;
	return style2;
} else {
	styleCache1[size] = style1;
	return style1;
}
    
  },
});

map.addLayer (clusters);
};


var curfloodlayer;

// tao flood layer
function makefloodlayer(croppolyline,geo,kbn,zone){
//console.log('crop',croppolyline,'kb',kbn,'zone',zone);
var geos =[]
var layertitle = ''


if(geo.features){
	
var fn = geo.features.length;


layertitle = 'Kịch bản ' + kbn + ' tại ' + zone ;



for (let i=0;i<fn;i++) {

var feaX = new GeoJSON().readFeatures(geo.features[i]);

geos.push(feaX[0]);

};

curfloodlayer =	new VectorLayer({ title: layertitle,
							  	
							  source: 	new VectorSource({
									 	features: geos,
											}),
										style: function (feature) {
										
									//	return pat.stylesGeo[feature.get('type')];
									//    return iotstyle.dynamicstyle('polystyle', parseFloat(feaX[0].get('f2')) / 10 );
										return iotstyle.dynamicstyle('polystyle',parseFloat(feature.get('f1')) / 10 );
									
																	},
																	
									  });


map.addLayer (curfloodlayer);

croplocal(croppolyline);	

info.innerText = zone + ' - ' + kb[kbn]  ;
info.style.opacity = 1;

};
};


//





//function addfield(geo){
//
////var flayer = []
//if(geo.features){
//	
//var fn = geo.features.length;
//var cl = 'rgba(x, 0, 0, 0.5)';
//var w =1;
//const clr = ['cyan','violet','blue','green','brown','Orange']
//var icl = 'red'
//for (let i=0;i<fn;i++) {
//let feaX = new GeoJSON().readFeatures(geo.features[i]);
//console.log(feaX[0].getGeometry().getType());
//
//icl = clr[parseInt(feaX[0].get('f2'))];
//let clx = cl.replace('x',100*i+55);		
//
//if (feaX[0].getGeometry().getType()==='MultiLineString'){
//		w=3
//	}else{
//		w=1
//	}
//
//if (feaX[0].getGeometry().getType()==='Point'){
////console.log('point');
////console.log(feaX[0].getGeometry());
////const iconFeature1 = new Feature({
////geometry: feaX[0].getGeometry(),
////});
////iconFeature1.setStyle(iconStyle[1]);
//
//feaX[0].setStyle(iconStyle[1]);
//
////feaX.pop();
////feaX.push(iconFeature1);
//};
//
//
//
//let l =	new VectorLayer({
//	
//	
//							  source: 	new VectorSource({
//									 	features: feaX,
//										}),	
//										
//							  style: new Style({
//									    stroke: new Stroke({
//									      color: icl,
//									      width: w,
//									    }),
//									    fill: new Fill({
//									      color: clx,
//									    }),
//										image: new CircleStyle({
//								          radius: 5,
//								          fill: new Fill({color: 'red'}),
//								        }),
//					
//					
//									  }),
//									
//							})
//				
//	map.addLayer (l);
//};
//}
//
//
//
////map.addLayer (layer1);
////map.addLayer (layer2);
////map.addLayer (layer3);
//
//};
//// test ve




//const raster = new TileLayer({
//  title: 'OSM',
//  visible:true,
//  source: new OSM(),
//});


//const pointlayer = new ImageLayer({
//   
//    source: new ImageWMS({
//      url: 'http://"+pat.hostIP+":8080/geoserver/wms',
//      params: {
//				'LAYERS': 'Ubuntu1:points',
//				
//				},
//      ratio: 1,
//      serverType: 'geoserver',
//	 
//    }),
// });
//
//const polylayer = new ImageLayer({
//   
//    source: new ImageWMS({
//      url: 'http://"+hostIP+":8080/geoserver/wms',
//      params: {
//				'LAYERS': 'Ubuntu1:polygons',
//				},
//      ratio: 1,
//      serverType: 'geoserver',
//      
//	 
//    }),
//});
//
//const linelayer = new ImageLayer({
//   
//    source: new ImageWMS({
//      url: 'http://"+hostIP+":8080/geoserver/wms',
//      params: {
//				'LAYERS': 'Ubuntu1:lines',
//				},
//      ratio: 1,
//      serverType: 'geoserver',
//      
//	 
//    }),
//});


//
//const iconStyle = new Style({
//  image: new Icon({
//    anchor: [0.5, 46],
//    anchorXUnits: 'fraction',
//    anchorYUnits: 'pixels',
//    src: 'data/icon.png',
//  }),
//});



const source = new VectorSource();

//const vector = new VectorLayer({
//  title: 'Sketch',
//  source: source,
//  style: new Style({
//    fill: new Fill({
//      color: 'rgba(255, 255, 255, 0.2)',
//    }),
//    stroke: new Stroke({
//      color: '#ffcc33',
//      width: 2,
//    }),
//    image: new CircleStyle({
//      radius: 7,
//      fill: new Fill({
//        color: '#ffcc33',
//      }),
//    }),
//  }),
//});

///**
// * Currently drawn feature.
// * @type {import("../src/ol/Feature.js").default}
// */
//let sketch;
//
///**
// * The help tooltip element.
// * @type {HTMLElement}
// */
//let helpTooltipElement;
//
///**
// * Overlay to show the help messages.
// * @type {Overlay}
// */
//let helpTooltip;
//
///**
// * The measure tooltip element.
// * @type {HTMLElement}
// */
//let measureTooltipElement;
//
///**
// * Overlay to show the measurement.
// * @type {Overlay}
// */
//let measureTooltip;
//
///**
// * Message to show when the user is drawing a polygon.
// * @type {string}
// */
//const continuePolygonMsg = 'Click to continue drawing the polygon';
//
///**
// * Message to show when the user is drawing a line.
// * @type {string}
// */
//const continueLineMsg = 'Click to continue drawing the line';
//
///**
// * Handle pointer move.
// * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
// */
//const pointerMoveHandler = function (evt) {
//  if (evt.dragging)  {
//    return;
//  };
//
// 
//if(typeSelect.value==='select'){
//	
//const pixel = map.getEventPixel(evt.originalEvent);
////displayFeatureInfo(pixel);
//
//	return;
//	};
//
//  /** @type {string} */
//  let helpMsg = 'Click to start drawing';
//
//  if (sketch) {
//    const geom = sketch.getGeometry();
//
//    if (geom instanceof Polygon) {
//      helpMsg = continuePolygonMsg;
//    } else if (geom instanceof LineString) {
//      helpMsg = continueLineMsg;
//    }
//  }
//
//  helpTooltipElement.innerHTML = helpMsg;
//  helpTooltip.setPosition(evt.coordinate);
//
//  helpTooltipElement.classList.remove('hidden');
//};


	//var xy = document.getElementById("XYCoor").value;
	var zxy = localStorage.getItem("Zoom");
	var xy = localStorage.getItem("XYCoor");
	var xc = 0;
	var yc = 0;
	var Zoomxy=14;
	
	if (zxy != null) {
	Zoomxy = parseInt(zxy);
	}


	console.log('local vp',xy);
	if (xy != null) {
		if (xy.indexOf(',')>0){
		xc = xy.split(",")[0];
		yc = xy.split(",")[1];
		}
	}

	
	if (xc*yc>0) {
		
	} else {
		var lon=108.1992462959395
		var lat=16.04634037849107
		 
		 xc = (lon * 20037508.34) / 180;
    	 yc = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
      	 yc = (yc * 20037508.34) / 180;
	}






const view = new View({
    center: [xc,yc],
    zoom: Zoomxy,
	//projection: 'DH1000',
    constrainResolution: true,
    
});


//// select interaction working on "singleclick"
//const selectSingleClick = new Select();
//
//// select interaction working on "click"
//const selectClick = new Select({
//  condition: click,
//});
//
//// select interaction working on "pointermove"
//const selectPointerMove = new Select({
//  condition: pointerMove,
//});
//
//const selectAltClick = new Select({
//  condition: function (mapBrowserEvent) {
//    return click(mapBrowserEvent) && altKeyOnly(mapBrowserEvent);
//  },
//});
//
//const selectob = new Select({
//  wrapX: false,
//condition: function (mapBrowserEvent) {
//    return click(mapBrowserEvent) && altKeyOnly(mapBrowserEvent);
//},
//});
//
//const modifyob = new Modify({
//  features: selectob.getFeatures(),
//});
//



//const featureOverlay = new VectorLayer({
//  source: new VectorSource(),
//  title: "Highlight",
////  map: map,
//  style: function (feature) {
//    //highlightStyle.getText().setText(feature.get('name1'));
//    return highlightStyle;
//  },
//});
//
let highlight;

const raster = new TileLayer({
  title:'Open Street Map',
  visible:false,
  source: new OSM(),
});

const sourcedrw = new VectorSource();
const vectordrw = new VectorLayer({
  source: sourcedrw,
   style: new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
          color: 'red',
        }),
      }),
    }),
//  style: {
//    'fill-color': 'rgba(255, 255, 255, 0.2)',
//    'stroke-color': '#ffcc33',
//    'stroke-width': 2,
//    'circle-radius': 7,
//    'circle-fill-color': '#ffcc33',
//  },
});

let hp = new Point(0,0);
	highlight=new Feature(hp);
    vectordrw.getSource().addFeature(highlight);


const map = new Map({
//controls: defaults({ "attribution": false }),
controls: defaultControls().extend([new InputPicControl()]),
//controls: defaultControls({attribution: false}).extend([attribution]), 
 //interactions: defaultInteractions().extend([ modifyob]),
 interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
 //interactions: defaultInteractions().extend([new PinchZoom()]),  
 layers: [
			raster,
			glayer,
			glayer1,
			vectordrw,
//			featureOverlay, 
//			clusters,
//			tifflayer,
//			imageLayer,
//			vector,
			 //lp,
			 			  new Graticule({
      // the style to use for the lines, optional.
	   title:'grid',
      strokeStyle: new Stroke({
        color: 'rgba(255,120,0,0.9)',
        width: 2,
        lineDash: [0.5, 4],
      }),
      showLabels: true,
      wrapX: false,
    }),
	    	
			],
  target: 'map',
  view: view, 
  //view: tiffsource.getView(),
});

//ticklayer("Google Sattelite Map",true);
//ticklayer("Google Street Map",true)
//ticklayer("Open Street Map",true)

map.addOverlay(popup);
map.addOverlay(popupicon);
//map.addLayer (lp);




function croplocal(fea){

//const f = new Feature(new Polygon(fea.geometry.coordinates));
//console.log(fea);

crop = new Crop({ feature: fea, inner:false });
curfloodlayer.addFilter(crop);

	
//	var coords = fea.geometry.coordinates;
//    var f = new Feature(new geom.MultiPolygon(coords));
//    var crop = new filter.Crop({ feature: f, inner:false });
//    osm.addFilter(crop);
//    var mask = new filter.Mask({ feature: f, inner:false, fill: new ol.style.Fill({ color:[255,255,255,0.8] }) });
//    osm.addFilter(mask);
//
//    function setFilter(){
//      mask.set('inner', $("#inner").prop('checked')); 
//      crop.set('inner', $("#inner").prop('checked')); 
//      mask.fillColor_ = $("#color").val();
//      // Activate
//      mask.set('active', false); 
//      crop.set('active', false); 
//      switch ($("#filter").val()){
//        case 'mask': mask.set('active', true); break;
//        default: crop.set('active', true); break;
//      }
//    }
//    setFilter();

}

//function openNav() {
//if (document.getElementById("mySidenav").style.width =="0"){
// document.getElementById("mySidenav").style.width = "250";
//} else {
//  document.getElementById("mySidenav").style.width = "250px";
//}
//}
var px
var ct
var idpx
var field


async function detailPrint() {
//console.log('waiting find',url);
const url  = "SELECT field, loaicongtrinh, bigdata  FROM public.info";
  
let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch("http://"+hostIP+":8000/selectdb/" + url).then(function(response) {
		     myResolve(response.json());
    })
  });

let detailp = await myPromise0;
//console.log(px);
//ct = detailp[k].bigdata;	
}


	
async function GetPhuongXa(url,co) {
//console.log('waiting find',url);

  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch("http://"+hostIP+":8000/selectdb/" + url).then(function(response) {
		     myResolve(response.json());
    })
  });

px = await myPromise0;
//console.log(px);
let x = `
   <table>
      <tbody>
			@body
       </tbody>
    </table>`;


if (px.length==0) {
ct={"Công trình thủy lợi":des}	
} else  {
	if (px[0].soctr>1) {
		ct={"Công trình thủy lợi": "nhóm " + px[0].soctr + " " + px[0].loaicongtrinh };
		pedit.style.display='none';	
		
	} else {
		ct = px[0].bigdata;	
	//	ct['Biểu tượng nhóm']=groupIcon(px[0].field);
	//	ct['Biểu tượng']=itemIcon(px[0].field);
	}
 
}



console.log(ct);

let body='';
//	body += '<br>';

// <tr><th align="left">Lô luống  </th>&nbsp<td><input id="name11"></input></td></tr>
 
for (f in ct) {
	//console.log( ((f!='lat') && (f!='lon')))
	if ((f!='lat') && (f!='lon')) {
	let fn = (f=='name')?px[0].loaicongtrinh:f;	
	body += '<tr><td style="width:250px">' + fn + '</td><th style="width:150px" align="left">' + ct[f] + '</th></tr>';

	}
} 
	// Tam khoa Icon trong popup
	// body += '<tr><td style="width:200px">Biểu tượng nhóm</td><th style="width:250px" align="left">' + groupIcon(field) + '<img src="icon/'+ groupIcon(field) +'"></th></tr>';
	// body += '<tr><td style="width:200px">Biểu tượng đơn</td><th style="width:250px" align="left">' + itemIcon(field) + '<img src="icon/'+ itemIcon(field) +'"></th></tr>';

x = x.replace("@body",body);
//let xx = x.replace('@danhtuch_1',px[0].danhtuch_1.substring(0, 1).toUpperCase() + px[0].danhtuch_1.substring(1) );
//xx = xx.replace('@diadanh_1',px[0].diadanh_1);
//
//xx = xx.replace('@danhtuchung',px[0].danhtuchun.substring(0, 1).toUpperCase() + px[0].danhtuchun.substring(1) );
//xx = xx.replace('@diadanh',px[0].diadanh);

//  const currentView = map.getView();
//  const currentResolution = currentView.getResolution();
//  const currentCenter = currentView.getCenter();





 	content.innerHTML = x;
    popup.setPosition(co);


};

pdel.addEventListener('click', function (){
console.log("http://"+hostIP+":8000/selectdb/" + strdelobj)	;

let url="http://"+hostIP+":8000/selectdb/" + strdelobj

delobj(url);

async function delobj(url) {

  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

await myPromise0;
modifyfield(curlay,field);

popup.setPosition(undefined);
}
	

	});


pedit.addEventListener('click', function (){
	
	psave.style.display='inline';
	pedit.style.display='none';
	pdel.style.display='inline';
	GetPhuongXaEdit();


//	document.getElementById('iconselect').addEventListener('change',function(){
//		alert('sss');
//	})




$('#iconselect').change(function() {
	
	$('#gricon').attr("src","icon/"+$(this).val());
});

$('#iconselect1').change(function() {
	
	$('#iticon').attr("src","icon/"+$(this).val());
});

	
});

psave.addEventListener('click', function (){
	
	const pkey = document.getElementsByClassName('pkey');
	const pvalue = document.getElementsByClassName('pvalue');
//	console.log(pkey);
//	console.log(pvalue);
let str	= '{'
	for (let i=0;i<pkey.length;i++) {
		if (pkey[i].value) {
			str += '"' +  pkey[i].value  + '":"'+pvalue[i].value + '",';
		}
	}
		str += '}';
		str = str.replace(',}','}');
		
		
	console.log(str);
	
//'let	urlup = "http://"+hostIP+":8000/selectdb/UPDATE public.thuyloi SET bigdata='" + str + "' WHERE thuyloi.bigdata ->> 'id'::text='" + idpx + "' AND field='" + field + "'";
let	urlup = "http://"+hostIP+":8000/selectdb/CALL public.updatethuyloi(" + idpx + ",'" + field + "','" + str + "')"   ;

	console.log(urlup);
		fetch(urlup).then(function(response) {
		     response.json();
    	});


const pvalue1 = document.getElementsByClassName('pvalue1');
	
urlup = "http://"+hostIP+":8000/selectdb/UPDATE public.icon SET groupicon='" + pvalue1[0].value + "', itemicon='"+ pvalue1[1].value + "' WHERE field='" + field + "'";

SetIcon(urlup);

async function SetIcon(url) {
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

 await myPromise0;


for (let i=0;i<icons.length;i++){
	if(icons[i].field===field){
		icons[i].groupicon=pvalue1[0].value;
		icons[i].itemicon=pvalue1[1].value;
		break;
	}
}


modifyfield(curlay,field);

popup.setPosition(undefined);
//location.reload()
}

//alert(urlup);

localStorage.setItem("XYCoor",view.getCenter());
localStorage.setItem("Zoom",view.getZoom());
//localStorage.setItem("Layer",layermenu);
console.log('view point',view.getCenter());




});


var currZoom;

map.on('moveend', function(e) {
  var newZoom = map.getView().getZoom();
  if (currZoom != newZoom) {
 
localStorage.setItem("XYCoor",view.getCenter());
localStorage.setItem("Zoom",view.getZoom());


    currZoom = newZoom;
  }
});


async function GetPhuongXaEdit() {

let x = `
   <table>
      <tbody>
			@body
       </tbody>
    </table>`;

let ct ;
let tenhienthi;
if (px.length==0) {
ct={"Công trình thủy lợi":des};
tenhienthi=des;	
} else {
 ct = px[0].bigdata;	
 tenhienthi=(ct["Tên hiển thị"]	== undefined)?des:ct["Tên hiển thị"];
}



//console.log(ct);

let body='';
	body += '<br>';

// <tr><th align="left">Lô luống  </th>&nbsp<td><input id="name11"></input></td></tr>
 
for (f in ct) {
	//console.log( ((f!='lat') && (f!='lon')))
	if ((f!='lat') && (f!='lon')) {
	let fn = (f=='name')?px[0].loaicongtrinh:f;	
	body += '<tr><td style="width:200px"><input class="pkey" value="' + fn + '"</input></td><th style="width:250px" align="left"><input style="width:100%" class="pvalue" value="' + ct[f] + '"></input></th></tr>';

	}
} 

let selectedicon='"' + groupIcon(field) + '"'
	body += '<tr><td style="width:200px"><input class="pkey1" value="Biểu tượng nhóm"</input></td><th style="width:250px" align="left">' + drop1.replace(selectedicon,selectedicon + ' selected') + '<img id="gricon" src="icon/'+ groupIcon(field) +'" style="width:20px;height:20px">' + '</th></tr>';
	
	selectedicon='"' + itemIcon(field) + '"'
	body += '<tr><td style="width:200px"><input class="pkey1" value="Biểu tượng đơn"</input></td><th style="width:250px" align="left">' + drop1.replace(selectedicon,selectedicon + ' selected').replace('iconselect','iconselect1') + '<img id="iticon" src="icon/'+ itemIcon(field) +'" style="width:20px;height:20px">' + '</th></tr>';


	body += '<tr><td style="width:200px"><input class="pkey" value="Tên hiển thị"</input></td><th style="width:250px" align="left"><input style="width:100%" class="pvalue" value="' + tenhienthi + '"></input></th></tr>';

	body += '<tr><td style="width:200px"><input class="pkey"></input></td><th align="left"><input style="width:100%" class="pvalue"></input></th></tr>';
	body += '<tr><td style="width:200px"><input class="pkey"></input></td><th align="left"><input style="width:100%" class="pvalue"></input></th></tr>';
  

x = x.replace("@body",body);
//let xx = x.replace('@danhtuch_1',px[0].danhtuch_1.substring(0, 1).toUpperCase() + px[0].danhtuch_1.substring(1) );
//xx = xx.replace('@diadanh_1',px[0].diadanh_1);
//
//xx = xx.replace('@danhtuchung',px[0].danhtuchun.substring(0, 1).toUpperCase() + px[0].danhtuchun.substring(1) );
//xx = xx.replace('@diadanh',px[0].diadanh);



 	content.innerHTML = x;
//    popup.setPosition(co);





};






var des;
var InputMode;
var curlay;
var curfield;
var strdelobj;
map.on('pointermove', function (evt) {
	if (InputMode) {
	let cen = this.getView().getCenter()
//    popupicon.setPosition(cen);
//	$('#coor').val(''+cen[0]+' '+ cen[1])
	//$('#coor').val(evt.coordinate);
	}
	})


	
	
	
function featureorigin(prefea,fea){
	
	console.log('deep',prefea.length,fea.length);
	
	if (fea.length==undefined) {
		
	let nextfea=fea.get('features');
	
	
	if (nextfea.length==undefined) {
		return featureorigin(fea,nextfea)
	} else {
		return fea;
	}
	
	} else {
		return prefea;
	}
}
	

map.on('singleclick', function (evt) {
//let	featureP = map.getFeaturesAtPixel(evt.pixel);
//for (let i=0;i<featureP.length;i++){
//	console.log(featureP[i].get('f1'));
//}
if (panel) {
$('#panel1').hide();
$('#panel2').hide();
$('#panel3').hide();
$('#panel4').hide();
panel=false;
printmode=false;
}


if ((drawMode==1) || (InputMode)) {
	return;
}

featureP = map.forEachFeatureAtPixel(evt.pixel, function(feature, layer)
	 {
		if(layer){
	
	console.log('more',layer);
	 curlay = layer;	
	 return feature;		
		}
	 

     });





console.log('click',featureP);
//console.log('deep1',featureP.get('features'));
//console.log('deep2',featureP.get('features').get('features'));
//console.log('click',featureP);
//let fn = featureP.length;

if (!featureP){
popup.setPosition(undefined);
return;	
}

if (featureP) {
	
//let featurePx = featureorigin(featureP,featureP.get('features'));
	console.log('fexx',featureP);
	//console.log(featureP[fn-1].get('f1')+ ' sau ' + i*0.5 +'-' + ((i+1)*0.5) + 'm')
let i = featureP.get('f1');

field = featureP.get('f4');

des  = featureP.get('f2');
let c = 1;


if (!i){
	let fx = featureP.get('features')
	console.log('fexx111',fx);
	let fxx= fx[0];
	c=fx.length;
	i= fxx.get('f1');
	field =fxx.get('f4');
	des  = fxx.get('f2');		
}

if (!i){
	let fx = featureP.get('features')[0].get('features')
	console.log('fexx222',fx);
	let fxx= fx[0];
	c=fx.length;
	i= fxx.get('f1');
	field =fxx.get('f4');
	des  = fxx.get('f2');		
}

idpx=i;


strdelobj="Delete FROM public.congtrinhthuyloi where id=" + i

let sqlstr  = "SELECT field, loaicongtrinh, bigdata, " + c +  " as soctr  FROM public.info where idx=" + i ;
console.log('sql',sqlstr);


    const size = map.getSize();
   // view.centerOn(evt.coordinate, size, [570, 500]);


GetPhuongXa(sqlstr,evt.coordinate);

pedit.style.display='inline';
pdel.style.display='none';
psave.style.display='none';
	
} else {
	popup.setPosition(undefined);
}
});
//

upimg.addEventListener('click', function (){
const url = "http://"+hostIP+":8000/upimages"	
let data = {id:1,content:urlimg.value ,filename:imgfilename.value ,completed: true}
let r = postData(url,data)	
console.log(r);
GetIcon(urliconthuyloi);
	
});



//const url = "http://172.19.199.17:3000/iot"
//Example POST method implementation:
async function postData(url = '', data = {}) {
// Default options are marked with *
const response = await fetch(url, {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', //cors, no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {

	'Accept': 'application/json',
  'Content-Type': 'application/json' 
  
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  body: JSON.stringify(data) // body data type must match "Content-Type" header
});
//return 0;  
//return response.json(); // parses JSON response into native JavaScript objects
return response;
}




















// menu button

var mainbar = new Bar();

    var save = new Button({
      html: '<i class="fa fa-download"></i>',
      title: "Save",
      handleClick: function(e) {
	
//		if (document.getElementById("mySidenav").style.width ==="0px"){
//			 document.getElementById("mySidenav").style.width = "250px";
//		} else {
//  		document.getElementById("mySidenav").style.width = "0px";
//		}
//		
    oldlabel = info.innerText
	info.innerText = '' ;
    info.style.opacity = 0;

		  var x = document.getElementById("myLinks");
		    x.style.display = "block";


      }
    });
   
//mainbar.addControl ( save );


//map.addControl(mainbar);

//const attribution = new Attribution({
//  collapsible: false,
//});


//import './layerswitcher'

//import 'ol-ext/control/layerswitcher.css'

/* LayerSwitcher control */
//import LayerSwitcher from 'ol-ext/control/LayerSwitcher'
//
//const switcher = new LayerSwitcher();
//map.addControl(switcher);


//print

import PrintDialog from 'ol-ext/control/PrintDialog'
import CanvasAttribution from 'ol-ext/control/CanvasAttribution'
import CanvasTitle from 'ol-ext/control/CanvasTitle'

map.addControl(new CanvasAttribution({ canvas: true }));
// Add a title control
map.addControl(new CanvasTitle({ 
  title: 'my title', 
  visible: false,
  style: new Style({ text: new Text({ font: '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif'}) })
}));

var printControl = new PrintDialog();
printControl.setSize('A4');
map.addControl(printControl);

$( ".ol-print").hide();

/* On print > save image file */
printControl.on(['print', 'error'], function(e) {
  // Print success
  if (e.image) {
    if (e.pdf) {
      // Export pdf using the print info
      var pdf = new jsPDF({
        orientation: e.print.orientation,
        unit: e.print.unit,
        format: e.print.size
      });
      pdf.addImage(e.image, 'JPEG', e.print.position[0], e.print.position[0], e.print.imageWidth, e.print.imageHeight);
      pdf.save(e.print.legend ? 'legend.pdf' : 'map.pdf');
    } else  {
      // Save image as file
      e.canvas.toBlob(function(blob) {
        var name = (e.print.legend ? 'legend.' : 'map.')+e.imageType.replace('image/','');
        saveAs(blob, name);
      }, e.imageType, e.quality);
    }
  } else {
    console.warn('No canvas to export');
  }
});


///
const scaleLine = new ScaleLine({bar: true, text: true, minWidth: 125});
map.addControl(scaleLine);

const dims = {
  a0: [1189, 841],
  a1: [841, 594],
  a2: [594, 420],
  a3: [420, 297],
  a4: [297, 210],
  a5: [210, 148],
};

// export options for html2canvase.
// See: https://html2canvas.hertzen.com/configuration
const exportOptions = {
  useCORS: true,
  ignoreElements: function (element) {
    const className = element.className || '';
    return !(
      className.indexOf('ol-control') === -1 ||
      className.indexOf('ol-scale') > -1 ||
      (className.indexOf('ol-attribution') > -1 &&
        className.indexOf('ol-uncollapsible'))
    );
  },
};

const exportButton = document.getElementById('export-pdf');

exportButton.addEventListener(
  'click',
  function () {
	//printmode = true;
    exportButton.disabled = true;
    document.body.style.cursor = 'progress';

    const format = document.getElementById('format').value;
    const resolution = document.getElementById('resolution').value;
    const scale = document.getElementById('scale').value;
    const dim = dims[format];
    const width = Math.round((dim[0] * resolution) / 25.4);
    const height = Math.round((dim[1] * resolution) / 25.4);
    const viewResolution = map.getView().getResolution();
    const scaleResolution =
      scale /
      getPointResolution(
        map.getView().getProjection(),
        resolution / 25.4,
        map.getView().getCenter()
      );

    map.once('rendercomplete', function () {
      exportOptions.width = width;
      exportOptions.height = height;
      html2canvas(map.getViewport(), exportOptions).then(function (canvas) {
        const pdf = new jspdf.jsPDF('landscape', undefined, format);
        pdf.addImage(
          canvas.toDataURL('image/jpeg'),
          'JPEG',
          0,
          0,
          dim[0],
          dim[1]
        );
        pdf.save('map.pdf');
        // Reset original map size
        scaleLine.setDpi();
        map.getTargetElement().style.width = '';
        map.getTargetElement().style.height = '';
        map.updateSize();
        map.getView().setResolution(viewResolution);
        exportButton.disabled = false;
        document.body.style.cursor = 'auto';
      });
    });

    // Set print size
    scaleLine.setDpi(resolution);
    map.getTargetElement().style.width = width + 'px';
    map.getTargetElement().style.height = height + 'px';
    map.updateSize();
    map.getView().setResolution(scaleResolution);

//printmode =false;
  },
  false
);
// end print
////profil
//
//var profil = new Profil();
//map.addControl(profil);
//
//var selStyle = [	
//    new Style({
//      stroke: Stroke({
//        color: [0,0,255],
//        width: 2
//      })
//    })
//  ];
//
//pt = new Feature(new Point(0,0));
//source.addFeature(pt);
//
//
// // Show a popup on over
//  profil.on(["over","out"], function(e) {
//    if (e.type=="over") profil.popup(e.coord[2]+" m");
//    drawPoint(e);
//  });
//
//// Draw a point on the map when mouse fly over profil
//  function drawPoint(e) {
//    if (!pt) return;
//    if (e.type=="over"){
//      // Show point at coord
//      pt.setGeometry(new Point(e.coord));
//      pt.setStyle(null);
//    } else {
//      // hide point
//      pt.setStyle([]);
//    }
//  };
//
////var pt, feature;
////  source.once('change',function(e) {
////    if (source.getState() === 'ready') {
////      feature = source.getFeatures()[0];
////      profil.setGeometry(feature);
////      pt = new ol.Feature(new ol.geom.Point([0,0]));
////      pt.setStyle([]);
////      source.addFeature(pt);
////    }
////  });
//
//
//// end profil



//let Xhighlight;
//const displayFeatureInfo = function (pixel) {
//
////featureP = map.getFeaturesAtPixel(evt.pixel)[0];
//floodlayers[0].getFeatures(pixel).then(function (features) {
//	 
////const features = map.getFeaturesAtPixel(pixel)
//
//if(features){
//	
//    const feature = features.length ? features[0] : undefined;
////    const info = document.getElementById('info');
////    if (features.length) {
////      info.innerHTML =decodeURI( feature.get('f1'));
////    } else {
////      info.innerHTML = '&nbsp;';
////    }
//
//	
//    if (feature !== highlight) {
//      if (Xhighlight) {
//		console.log('remove',Xhighlight ,highlight,new Date());
//        featureOverlay.getSource().removeFeature(highlight);
//      }
//      if ((feature) )  {
//        
//		console.log(feature.get('f2'));
//		let gc=feature.get('f2');
//		if (gc>50){
//		featureOverlay.getSource().addFeature(feature);	
//		highlight = feature;
//		Xhighlight=true;
//		} else {
//		Xhighlight = false;
//		}
//      } else {
//		Xhighlight = false;
//		highlight = '';
//		}
//      
//    }
//	
//  }
//
//}
//
//}

//
//const displayFeatureInfo = function (pixel) {
//  floodlayers[0].getFeatures(pixel).then(function (features) {
//    const feature = features.length ? features[0] : undefined;
//    const info = document.getElementById('info');
//    if (features.length) {
//      info.innerHTML = feature.get('f1');
//    } else {
//      info.innerHTML = '&nbsp;';
//    }
//
//    if (feature !== highlight) {
//      if (highlight) {
//        featureOverlay.getSource().removeFeature(highlight);
//      }
//      if (feature) {
//        featureOverlay.getSource().addFeature(feature);
//      }
//      highlight = feature;
//    }
//  });
//};
//
//



//function setSource() {
//  const source = new Static({
//    url:'images/antrach.png',
//    crossOrigin: '',
//    projection: 'EPSG:27700',
//    imageExtent: imageExtent,
//    imageSmoothing: true,
//  });
//  imageLayer.setSource(source);
//}
//setSource();

var small = false;

//map.on('moveend', onMoveEnd);
//
//function onMoveEnd(evt) {
//  const map1 = evt.map;
////        var zoom;
////        var resolution = this.getResolution();
////        if (resolution !== undefined) zoom = this.getZoomForResolution(resolution);
////        return zoom;
//
//
//  const extent = map1.getView().calculateExtent(map1.getSize());
//
//	small = map1.getView().getZoom() > 15;
//	console.log(map1.getView().getZoom(),small);
//
//
//
//  //const bottomLeft = toLonLat(getBottomLeft(extent));
//  //const topRight = toLonLat(getTopRight(extent));
// //console.log(extent);
//};
//
//
//map.on('pointermove', pointerMoveHandler);
//
//
////map.getViewport().addEventListener('mouseout', function () {
////	
////  //helpTooltipElement.classList.add('hidden');
////});
//
//const target = document.getElementById('map');
//const typeSelect = document.getElementById('type');
//
//const conceptSelect = document.getElementById('conceptSelect');
////const deepSelect = document.getElementById('deep');
//
//
////const bsave = document.getElementById('bsave');
////const bhide = document.getElementById('bhide');
////var vimagelayer = true;
//
////bhide.addEventListener('click', function () {
////vimagelayer = !(vimagelayer);
////imageLayer.set('visible', vimagelayer);	
////});
	


var dwggeom = [];
var lastpoint;


//let draw; // global so we can remove it later

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
//const formatLength = function (line) {
//  const length = getLength(line);
//  let output;
//  if (length > 100) {
//    output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
//  } else {
//    output = Math.round(length * 100) / 100 + ' ' + 'm';
//  }
//
//  return output;
//};
//
///**
// * Format area output.
// * @param {Polygon} polygon The polygon.
// * @return {string} Formatted area.
// */
//const formatArea = function (polygon) {
//  const area = getArea(polygon);
//  let output;
//  if (area > 10000) {
//    output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
//  } else {
//    output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
//  }
//  return output;
//};
//
//
//
//
//const modify = new Modify({source: source});
//map.addInteraction(modify);
//let snap; // global so we can remove them later
//
//
//
//function addInteraction() {
//  const type = typeSelect.value == 'area' ? 'Polygon' : (typeSelect.value == 'length' ? 'LineString' : (typeSelect.value == 'point' ? 'Point' : 'Select'));
//if(type==='Select'){
//console.log('add select ob')
//map.removeInteraction(draw);
//
//map.addInteraction(selectob);
//
////map.addInteraction(modify);
// return;
//}
//
//map.removeInteraction(selectob);
//
//  draw = new Draw({
//    source: source,
//    type: type,
//    style: new Style({
//      fill: new Fill({
//        color: 'rgba(255, 255, 255, 0.2)',
//      }),
//      stroke: new Stroke({
//        color: 'rgba(0, 0, 0, 0.5)',
//        lineDash: [10, 10],
//        width: 2,
//      }),
//      image: new CircleStyle({
//        radius: 5,
//        stroke: new Stroke({
//          color: 'rgba(0, 0, 0, 0.7)',
//        }),
//        fill: new Fill({
//          color: 'rgba(255, 255, 255, 0.2)',
//        }),
//      }),
//    }),
//  });
//
//
//  
//  snap = new Snap({source: source});
//  map.addInteraction(snap);
//  map.addInteraction(draw);
//
//  createMeasureTooltip();
//  createHelpTooltip();
//
//  let listener;
//  draw.on('drawstart', function (evt) {
//	drawMode=1;
//    // set sketch
//    sketch = evt.feature;
//   // dwggeom.push(evt.coordinate);
//
//    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
//    let tooltipCoord = evt.coordinate;
//
//    listener = sketch.getGeometry().on('change', function (evt) {
//      const geom = evt.target;
//      //
//      let output;
//      if (geom instanceof Polygon) {
//        output = formatArea(geom);
//        tooltipCoord = geom.getInteriorPoint().getCoordinates();
//       
//      } else if (geom instanceof LineString) {
//        output = formatLength(geom);
//        tooltipCoord = geom.getLastCoordinate();
//      }
//      measureTooltipElement.innerHTML = output;
//      measureTooltip.setPosition(tooltipCoord);
//      lastpoint=tooltipCoord;
//
//    });
//
//
//
//  });
//
//  draw.on('drawend', function (e) {
//    drawMode=0;
//	measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
//    measureTooltip.setOffset([0, -7]);
//    // unset sketch
//    sketch = null;
//    // unset tooltip so that a new one can be created
//    measureTooltipElement = null;
//    createMeasureTooltip();
//    unByKey(listener);
//
//dwggeom.push([e.feature,zcoord]);
//zcoord=[];
//console.log(dwggeom);
//
////var currentFeature = e.feature;//this is the feature fired the event
////var restOfFeats = source.getFeatures();//rest of feats
////var allFeats = restOfFeats.concat(currentFeature);//concatenate the event feat to the array of source feats
////console.log(allFeats)
//
//
//
//  });
//}

/**
 * Creates a new help tooltip
 */
//function createHelpTooltip() {
//  if (helpTooltipElement) {
//    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
//  }
//  helpTooltipElement = document.createElement('div');
//  helpTooltipElement.className = 'ol-tooltip hidden';
//  helpTooltip = new Overlay({
//    element: helpTooltipElement,
//    offset: [15, 0],
//    positioning: 'center-left',
//  });
//  map.addOverlay(helpTooltip);
//}

/**
 * Creates a new measure tooltip
 */
//function createMeasureTooltip() {
//  if (measureTooltipElement) {
//    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
//  }
//  measureTooltipElement = document.createElement('div');
//  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
//  measureTooltip = new Overlay({
//    element: measureTooltipElement,
//    offset: [0, -15],
//    positioning: 'bottom-center',
//    stopEvent: false,
//    insertFirst: false,
//  });
//  map.addOverlay(measureTooltip);
//}

/**
 * Let user change the geometry type.
 */
//conceptSelect.onchange = function () {
//console.log(conceptSelect.value);
////MakeFlood(conceptSelect.value,deepSelect.value);	
////MakeFloodApart(conceptSelect.value);
//}


function showlocal (k,kb){
	
	if (curfloodlayer) {
	//console.log('existing');
	//curfloodlayer.removeFilter(crop);
	map.removeLayer(curfloodlayer);
	}

	
	var feaP = new GeoJSON().readFeatures(geolocal.features[k]);

let feature=feaP[0];

 if (feature !== highlight) {
      if (highlight) {
        featureOverlay.getSource().removeFeature(highlight);
		
      }
      if (feature) {
        featureOverlay.getSource().addFeature(feature);
      }
      highlight = feature;
    }

const polygonx = highlight.getGeometry();
//var ext = highlight.getExtent();
view.fit(polygonx, {padding: [2, 2, 2, 2]});

MakeFlood(feature,k,feature.get('f1'),kb);


};

var oldlabel;


var renderJs = (function () {
    function renderJs() {
    }

renderJs.restorelabel = function () {
  	info.innerText = oldlabel ;
    info.style.opacity = 1;
};

renderJs.onTest = function () {
	
}

renderJs.onPushButtonClicked = function (x,oTarget) {
	
	let k = Math.ceil(x / 100)-1;
	let kb = x % 100;

	//console.log('zone',k,'kich ban',kb);    

	  var x = document.getElementById("myLinks");
		  x.style.display = "none";
	showlocal(k,kb);
};

    return renderJs;
})();

window['renderJs']=renderJs;



////

var user_table = Tabulator;
var tableDataNested = [
{'id':1000,'kenh':'Bản dồ chuyên đề','loai':'ho,dap,bom,moclu,kesontra,kebvbo,kegiaothong,pttt,nguon,tuoi,qhho,qhdap,qhbom,qhkenh,qhde,qhcong,qhnguon,truccanh,trama,tramb','geostyle':1000,_children:[

{'id':1100,'kenh':'Dữ liệu công trình thủy lợi','loai':'ho,dap,bom,moclu,kesontra,kebvbo,kegiaothong,pttt,nguon,tuoi','geostyle':1100,_children:[
{'id':1101,'kenh':'Công trình hồ chứa','loai':'ho','geostyle':0},
{'id':1102,'kenh':'Công trình đập','loai':'dap','geostyle':0},
{'id':1103,'kenh':'Công trình trạm bơm','loai':'bom','geostyle':0},
{'id':1104,'kenh':'Cột mốc bão lũ','loai':'moclu','geostyle':0},
{'id':1105,'kenh':'Kè bán đảo Sơn Trà','loai':'kesontra','geostyle':1},
{'id':1106,'kenh':'Kè bảo vệ bờ','loai':'kebvbo','geostyle':2},
{'id':1107,'kenh':'Kè giao thông','loai':'kegiaothong','geostyle':3},
{'id':1108,'kenh':'Nhà phòng tránh thiên tai','loai':'pttt','geostyle':0},
{'id':1109,'kenh':'Công trình tạo nguồn','loai':'nguon','geostyle':0},
{'id':1110,'kenh':'Khu tưới','loai':'tuoi','geostyle':0},
{'id':1111,'kenh':'Đường bao','loai':'biengioi','geostyle':4}]},

{'id':1200,'kenh':'Dữ liệu công trình quy hoạch','loai':'qhho,qhdap,qhbom,qhkenh,qhde,qhcong,qhnguon','geostyle':27,_children:[
{'id':1201,'kenh':'Hồ chứa quy hoạch','loai':'qhho','geostyle':11},
{'id':1202,'kenh':'Đập quy hoạch','loai':'qhdap','geostyle':11},
{'id':1203,'kenh':'Trạm bơm quy hoạch','loai':'qhbom','geostyle':11},
{'id':1204,'kenh':'Tuyến kênh quy hoạch','loai':'qhkenh','geostyle':11},
{'id':1205,'kenh':'Tuyến đê quy hoạch','loai':'qhde','geostyle':11},
{'id':1206,'kenh':'Cống quy hoạch','loai':'qhcong','geostyle':11},
{'id':1207,'kenh':'Công trình tạo nguồn','loai':'qhnguon','geostyle':11}]},

{'id':1300,'kenh':'Trạm khí tượng thủy văn','loai':'truccanh,trama,tramb','geostyle':27,_children:[
{'id':1301,'kenh':'Trạm trực canh','loai':'truccanh','geostyle':11},
{'id':1302,'kenh':'Trạm B','loai':'trama','geostyle':11},
{'id':1303,'kenh':'Trạm C','loai':'tramb','geostyle':11}]},]},

{'id':2000,'kenh':'Bản đồ tích hợp','loai':'2000','geostyle':'',_children:[
{'id':2101,'kenh':'Google Sattelite Map','loai':'','geostyle':18},
{'id':2202,'kenh':'Google Street Map','loai':'','geostyle':18},
{'id':2303,'kenh':'Open Street Map','loai':'','geostyle':5}]},

]



kenhformat= function(cell, formatterParams, onRendered){
 
let r = cell.getRow();
let id = '' + r.getData().id + '-';

console.log('format-',id)
layermenu.push(r);

    return (id.indexOf('00-')>0)?"<strong>" + cell.getValue() + "</strong>":cell.getValue(); //return the contents of the cell;
}


iconformat= function(cell, formatterParams, onRendered){
 
let r = cell.getRow();
let id = '' + r.getData().id + '-';

//console.log('format',id)
 return (id.indexOf('00-')>0 || r.getData().id>1999)?"":"<i class='fa fa-plus'></i>";
//return (id.indexOf('0-')=-1)?"<i class='fa fa-arrow-left'></i>":""; //return the contents of the cell;
}


findformat= function(cell, formatterParams, onRendered){
let r = cell.getRow();
let id = '' + r.getData().id + '-';
 return ( r.getData().id>1999)?"":"<i class='fa fa-search'></i>";
}



var  columnx = [
	
	
   //headerMenuIcon:buttonContents,
    {title:"Kênh",headerSort:false, field:"kenh", width:170,responsive:0,formatter:kenhformat},
    {title:"loai",headerSort:false, field:"loai", visible:false},
  //  {title:"geostyle",headerSort:false, field:"geostyle", visible:false},

	{title:"Ẩn",field:"rowselectx",formatter:"rowSelection", hozAlign:"center", width:10, headerSort:false, cellClick:function(e, cell){
       //cell.getRow().toggleSelect()
		console.log(cell.getRow().getData().id);



	}},

    {title:"F",headerSort:false, field:"geostyle", width:5,formatter:findformat, cellClick:function(e, cell){
	
	//alert(cell.getRow().getData().id);
	//layerselected="," + cell.getRow().getData().id+",2202";
	//user_table.deselectRow();
	//restorelayer();
	
	restorelayertheoloai(cell.getRow().getData().loai)
	$('#findheader').html('<strong>'+cell.getRow().getData().kenh+'</strong>')
	timtheoloai(cell.getRow().getData().loai);
	
	findpanel()	
	//showlayerIso(cell.getRow().getData().kenh);
	//showlayer("Google Street Map");
	//showlayer(cell.getRow().getData().kenh);
	//cell.getRow().select();
	//showlayerIso(cell.getRow().getData().kenh);
	}},


    {title:"H",headerSort:false, field:"geostyle",width:5,formatter:iconformat, cellClick:function(e, cell){
       //cell.getRow().toggleSelect()
	InputMode=true;
	drawMode=1;
	//user_table.deselectRow();
	cell.getRow().select();
	
	//showlayerIso(cell.getRow().getData().kenh);
	
	console.log(cell.getRow().getData().id);
	
	dwggeom=[];
	
	let cen = map.getView().getCenter()
	
	$('#newobj').html(' Thêm: ' + cell.getRow().getData().kenh);
	$('#objtype').val(cell.getRow().getData().loai);
	$('#coor').val("");

	$('#objgeostyle').val( cell.getRow().getData().geostyle);
	$('#type').prop('disabled', false);
	if (cell.getRow().getData().geostyle>0){
		$('#type').show();
		$('#type').val("Point");
		//$('#type').val("LineString");
		popupicon.setPosition(cen);
	} else {
		$('#type').hide();
		$('#type').val("Point");
		
		popupicon.setPosition(cen);
		  map.removeInteraction(draw);
		  map.removeInteraction(snap);
		  addInteractions();
	}
	
	}},




    ];


function display(disp){

user_table = new Tabulator("#user-table", {
	layout:"fitColumns",
	scrollToColumnPosition: "center",
	rowHeight:80,
    height:"550px",
    data:tableDataNested,
	//groupToggleElement:"header",
    dataTree:true,
    dataTreeStartExpanded:true,
	columns:columnx,

	rowClick:function(e, row){ //trigger an alert message when the row is clicked
 	//	alert("Row " + row.getData().macaytrong + " Clicked!!!!");
	
 	},
    rowSelected:function(row){
	
	showlayer(row.getData().kenh,true)
	
		if (user_table.getSelectedRows().length>1) {
			popupicon.setPosition(undefined);
			console.log(user_table.getSelectedRows().length)
			
		}


	


	if (row.getData().id % 1000 ==0){
		for (let i=0;i<layermenu.length;i++){
			if  ((layermenu[i].getData().id>row.getData().id) && (layermenu[i].getData().id<row.getData().id+1000)){
				layermenu[i].select();
			}
		}
	} else if (row.getData().id % 100 ==0) {
		for (let i=0;i<layermenu.length;i++){
			if ((layermenu[i].getData().id>row.getData().id) && (layermenu[i].getData().id<row.getData().id+100)) {
				layermenu[i].select();
			}
		}

	}	
	
	if (layerselected.indexOf(',' + row.getData().id)<0){
		layerselected += ',' + row.getData().id;
	}
		
	
	localStorage.setItem("layerselected",layerselected);
    console.log(layerselected);

    },
 	
	rowDeselected:function(row){
		showlayer(row.getData().kenh,false);
		
			if (row.getData().id % 1000 ==0){
		for (let i=0;i<layermenu.length;i++){
			if ((layermenu[i].getData().id>row.getData().id) && (layermenu[i].getData().id<row.getData().id+1000)) {
				layermenu[i].deselect();
			}
		}
	} else if (row.getData().id % 100 ==0) {
		for (let i=0;i<layermenu.length;i++){
			if ((layermenu[i].getData().id>row.getData().id) && (layermenu[i].getData().id<row.getData().id+100)) {
				layermenu[i].deselect();
			}
		}

	}	
	
	
	if (layerselected.indexOf(',' + row.getData().id)>-1){
		layerselected=layerselected.replace(',' + row.getData().id,'');
	}
		
	localStorage.setItem("layerselected",layerselected);
	console.log(layerselected);	
    },
});

//user_table.addColumn({title:"Age"}, false, "name");

var rowx = user_table.getRows();
let	children = rowx[1].getTreeChildren()[2].getTreeChildren();
console.log('children....',children)
}


display(1);


function restorelayertheoloai(loaistr){

for (let i=0;i<layermenu.length-3;i++){
	console.log('bat theo loai',layermenu[i].getData().loai + ' in ' + loaistr)
	
	if ((','+ loaistr).indexOf(','+layermenu[i].getData().loai)>-1) {
		layermenu[i].select();
		showlayer(layermenu[i].getData().kenh,true)
	} else {
		showlayer(layermenu[i].getData().kenh,false)
		layermenu[i].deselect();
	}
	
}

}


function restorelayer(){
	
	

//if (layerselected)

console.log('old...',layerselected,layermenu,layermenu.length);

//for (let i=0;i<layermenu.length;i++){
//	console.log('check',layermenu[i].getData().id)
//	
//	if (layerselected.indexOf(','+layermenu[i].getData().id)>-1) {
//		layermenu[i].select();
//	}
//	
//}
	
	
var rowx = user_table.getRows();
let layerselected1=','+layerselected;
for (let i=0;i<rowx.length;i++){
	//console.log(rowx[i].getData().kenh,rowx.length);
let	children = rowx[i].getTreeChildren();

		for (let j=0;j<children.length;j++){
			//console.log(children[j].getData().kenh);
							if (layerselected1.indexOf(','+children[j].getData().id)>-1) {
							children[j].select();
							console.log('ok-->',children[j].getData().kenh);
							showlayer( children[j].getData().kenh,true)
							} else {
								children[j].deselect();
								showlayer( children[j].getData().kenh,false)
							}
			let cchildren = children[j].getTreeChildren();
			for (let k=0;k<cchildren.length;k++){
				console.log('check',cchildren[k].getData().kenh);
					if (layerselected1.indexOf(','+cchildren[k].getData().id)>-1) {
							cchildren[k].select();
							console.log('ok-->',cchildren[k].getData().kenh);
							showlayer( cchildren[k].getData().kenh,true)
						}else {
							cchildren[k].deselect();
								showlayer( cchildren[k].getData().kenh,false)
							}
						
				}
		}
	};
	

	
 

}


function ticklayer(layername,t){

var rowx = user_table.getRows();

for (let i=0;i<rowx.length;i++){

let	children = rowx[i].getTreeChildren();
		for (let j=0;j<children.length;j++){
		
			let cchildren = children[j].getTreeChildren();
			for (let k=0;k<cchildren.length;k++){
				console.log(cchildren[k].getData().kenh);
					if (cchildren[k].getData().kenh==layername) {
							if (t) {
								cchildren[k].select();
							} else {
								cchildren[k].deselect();
							}
						}
				}
		}
	};
}
 
function showlayer(lname,vis){
	map.getLayers().forEach(function(l) {
		let ln=l.get("title");
        //console.log(l,ln);
		if(ln===lname){
			l.setVisible(vis);
			//console.log('-->',ln)
		}
      });
}




function showlayerIso(lname){
	map.getLayers().forEach(function(l) {
		let ln=l.get("title");
        //console.log(l,ln);
		if(ln===lname){
			l.setVisible(true);
			//console.log('-->',ln)
		} else if ((ln) && (ln.indexOf('Map')<1)) {
			l.setVisible(false);
		} else {
			console.log('de danh',ln)
		}
      });
}




///


var iconrowid;




function displayicon(disp){

 icon_table = new Tabulator("#icon-table", {
	
 
	layout:"fitDataStretch",
//	headerFilterParams:{values:true},
	height:"300px",
	selectable:1,
    data:icons,           //load row data from array
    //layout:"fitDataStretch",
	//layout:"fitColumns",      
    addRowPos:"bottom",          //when adding a new row, add it to the top of the table
   // history:true,             //allow undo and redo actions on the table
    columns:[                 //define the table columns
        
		{title:"Mã", field:"id", frozen:true,hozAlign:"center",sorter:"number", width:10},
		
		{title:"Loai", field:"field",visible:false,frozen:true, width:60,editor:'input',formatter:"textarea"},
		
		{title:"Tên file ", field:"itemicon", width:80,editor:'input',formatter:"textarea"},

				{title:"Hình ảnh", field:"itemicon", width:4/0,formatter:"image",
				formatterParams:{
			    height:"40px",
			    width:"40px",
			    urlPrefix:"icon/",
			    urlSuffix:"?"+ new Date().getTime(),
				}},

  ],
	rowClick:function(e, row){ //trigger an alert message when the row is clicked
 	//	alert("Row " + row.getData().macaytrong + " Clicked!!!!");

 	},
    rowSelected:function(row){
		iconrowid=row.getData().id;
    },
 	
	rowDeselected:function(row){
    },

});


let fstr = [];	
	fstr.push({field:"field",type:"=",value:"chung"});
	icon_table.setFilter(fstr);
	

$('#delete-icon').click(function(){
	
	var selectedRows = icon_table.getSelectedRows();
	const urldelicon = "http://"+hostIP+":8000/selectdb/Delete FROM public.icon where id=" + selectedRows[0].getData().id;
	console.log(urldelicon);
	fetch(urldelicon).then(function(response) {
		console.log(JSON.stringify(response));
	})
    selectedRows[0].delete();

})

}


$('#objcloser').click(function(){
  popupicon.setPosition(undefined);
  map.removeInteraction(draw);
  map.removeInteraction(snap);
 
if (sketch){
	vectordrw.getSource().removeFeature(sketch)
  	sketch=null;
 }
 

  $('#objcloser').blur();
  InputMode=false;	
  return false;
	
})

$('#objsave').click(function(){


console.log('save drw',dwggeom);
let urlinsobj = savedraw($('#objname').val(),$('#objtype').val());

//if ($('#objtype').val()===''){
//	alert('Không có');
//	return;
//}
//
//var urlins = "http://"+hostIP+":8000/selectdb/INSERT INTO public.congtrinhthuyloi( name,  field, geom)"
//	urlins += "VALUES ('@name',  '@field', @geom);";
//	
//	let coorstr= "st_transform(st_geomfromtext('POINT("+ $('#coor').val() + ")',3857),4326)"; 
//		
//	urlins=urlins.replace('@geom',coorstr)	
//	
//	urlins=urlins.replace('@name',$('#objname').val())	
//	
//	urlins=urlins.replace('@field',$('#objtype').val());
	
//	console.log(urlins);
	
savenewIcon(urlinsobj);

async function savenewIcon(url) {
  let myPromise0 = new Promise(function(myResolve, myReject) {
	fetch(url).then(function(response) {
		     myResolve(response.json());
    })
  });

 await myPromise0;

dwggeom=[];

let selectlayer = $('#newobj').text().replace(' Thêm: ','');
let loaict=$('#objtype').val();

modifyfieldbylayername(selectlayer ,loaict);

$('#objcloser').click();
//  popupicon.setPosition(undefined);
//  map.removeInteraction(draw);
//  map.removeInteraction(snap);
//  sketch=null;
//
//  $('#objcloser').blur();
//  InputMode=false;
//location.reload()
}

})
 



//////////////MODIFYWWWWWWW



let sketch;

const modify = new Modify({source: sourcedrw});
map.addInteraction(modify);

let draw, snap; // global so we can remove them later
const typeSelect = document.getElementById('type');

function addInteractions() {
  draw = new Draw({
    source: sourcedrw,
    type: typeSelect.value,
  });
  map.addInteraction(draw);
  snap = new Snap({source: sourcedrw});
  map.addInteraction(snap);



  let listener;
  draw.on('drawstart', function (evt) {
	drawMode=1;
    // set sketch
    sketch = evt.feature;
   // dwggeom.push(evt.coordinate);

    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
   // let tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on('change', function (evt) {
      const geom = evt.target;
      //
      let output;


	if (geom instanceof Point) {
	const Coords =geom.getCoordinates();
	 coorstr= Coords.toString();
	$('#coor').val(coorstr);
		
	}



      if (geom instanceof Polygon) {
     //   output = formatArea(geom);
      //  tooltipCoord = geom.getInteriorPoint().getCoordinates();
       
      } else if (geom instanceof LineString) {
      //  output = formatLength(geom);
     //   tooltipCoord = geom.getLastCoordinate();
      }

    });



  });

  draw.on('drawend', function (e) {
    drawMode=0;
    // unset sketch
   // sketch = null;
    // unset tooltip so that a new one can be created
// map.removeInteraction(draw);
     unByKey(listener);
//feaX[0].getGeometry().setCoordinates(crds)


//$('#coor').val(e.feature.coordinate);
dwggeom.push([e.feature]);

let obj = e.feature.getGeometry();
//var geom =obj.transform('EPSG:3857', 'EPSG:4326');
//
//console.log('draw end',obj.getType());

	if (sketch) {
	let coorstrL ='';	
    const geom = sketch.getGeometry();
	console.log('sketch', geom)
	
	if (geom instanceof Point) {
	map.removeInteraction(draw);	
	const Coords =geom.getCoordinates();
	coorstrL= Coords.toString().replace(',',' ');
	
		
	} else if (geom instanceof Polygon) {
    map.removeInteraction(draw);
	const ringCoords =geom.getCoordinates()[0];
	coorstrL = ringCoords[0].toString().replace(',',' ');
	for (i = 1; i < ringCoords.length; i++) {
    console.log(ringCoords[i]);
    coorstrL =  coorstrL + ',' + ringCoords[i].toString().replace(',',' ');
	}


    } else if (geom instanceof LineString) {
 
	const ringCoords =geom.getCoordinates();

		coorstrL = coorstrL + '('+ ringCoords[0].toString().replace(',',' ');
		for (i = 1; i < ringCoords.length; i++) {
		   		
	    	coorstrL =  coorstrL + ',' + ringCoords[i].toString().replace(',',' ');			

        }
		coorstrL = coorstrL + ')';
	}
	console.log('cooor',coorstrL);
	$('#coor').val(coorstrL);
    }
  




  });


}

/**
 * Handle change event.
 */
typeSelect.onchange = function () {
  map.removeInteraction(draw);
  map.removeInteraction(snap);
  addInteractions();

$('#type').prop('disabled', 'disabled');
};






const pointerMoveHandler = function (evt) {
	
	//$('#coor').val(evt.coordinate);


  if (evt.dragging)  {
	
	
	if (sketch) {
	let coorstrL ='';	
    const geom = sketch.getGeometry();
	console.log('sketch', geom)
	
	if (geom instanceof Point) {
	const Coords =geom.getCoordinates();
	coorstrL= Coords.toString().replace(',',' ');
	} 
	console.log('cooor',coorstrL);
	$('#coor').val(coorstrL);
    }
  

	
	
	
    return;
  };

 

 

 
  
};


map.on('pointermove', pointerMoveHandler);


function savedraw(name,field){


var urlins = "http://"+hostIP+":8000/selectdb/INSERT INTO public.congtrinhthuyloi( name,  field, geom)"
	urlins += "VALUES ('" + name + "',  '" + field + "', @geom);";
	
//	let coorstr= "st_transform(st_geomfromtext('POINT("+ $('#coor').val() + ")',3857),4326)"; 


var poly = "st_geomfromtext('POLYGON((";
var line = "st_geomfromtext('MULTILINESTRING(";
var point= "st_geomfromtext('POINT(";
	
var coorstrL ='';

console.log('num geo',dwggeom.length);

for (j = 0; j < dwggeom.length; j++) {
let obj = dwggeom[j][0].getGeometry();
let zcoor = [];


if (obj.getType() === 'LineString') {
	var geom =obj.transform('EPSG:3857', 'EPSG:4326');
	const ringCoords =geom.getCoordinates();
        zcoor[0] = (zcoor[0]==undefined ? 0:zcoor[0] );

		coorstrL = coorstrL + '('+ ringCoords[0].toString().replace(',',' ')+' '+zcoor[0];
		for (i = 1; i < ringCoords.length; i++) {
		   	zcoor[i] = (zcoor[i]==undefined ? 0:zcoor[i] );	
	    	coorstrL =  coorstrL + ',' + ringCoords[i].toString().replace(',',' ')+' '+zcoor[i];
			console.log(zcoor[i]);
        }
		coorstrL = coorstrL + '),';
	}
};
	
coorstrL = coorstrL + ','
coorstrL = coorstrL.replace(',,','');
console.log("Line=");
console.log(coorstrL);




for (j = 0; j < dwggeom.length; j++) {
var insertpolystr = '';	
var feature = dwggeom[j][0];	
var coorstr ='';
let obj = feature.getGeometry();
var geom =obj.transform('EPSG:3857', 'EPSG:4326');

   console.log(obj.getType());
if (obj.getType() === 'Polygon') {
	//const ringCoords = geom.getLinearRing().getCoordinates();
	//var geom = e.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
	const ringCoords =geom.getCoordinates()[0];
	coorstr = ringCoords[0].toString().replace(',',' ');
	for (i = 1; i < ringCoords.length; i++) {
    //console.log(ringCoords[i]);
    coorstr =  coorstr + ',' + ringCoords[i].toString().replace(',',' ');
    }

insertpolystr = poly + coorstr + ')';
//console.log(insertpolystr);

};

if (obj.getType() === 'LineString') {
	
//	const ringCoords =geom.getCoordinates();
	
//	coorstr = ringCoords[0].toString().replace(',',' ');
//	for (i = 1; i < ringCoords.length; i++) {
//    console.log(ringCoords[i]);
//    coorstr =  coorstr + ',' + ringCoords[i].toString().replace(',',' ');
//    }

//    console.log(coorstr);

   if (coorstrL.length>0){
		insertpolystr = line + coorstrL;
	    //console.log(insertpolystr );
		
		//coorstrL='';
	}
	};

if (obj.getType() === 'Point') {
	const Coords =geom.getCoordinates();
	 coorstr= Coords.toString();
     coorstr=coorstr.replace(',',' ')
	console.log(Coords);
insertpolystr = point + coorstr;



}
}

insertpolystr=insertpolystr+")')";

//console.log(insertpolystr);

urlins  = urlins.replace('@geom',insertpolystr)

console.log(urlins);

return urlins; 
}



///////////////MODIFY